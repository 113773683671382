import React from 'react';
import './Home.scss';

import Banner from './Banner';
import Bio from './Bio';
import Members from './Members';

const Home = () => {
  return (
    <div className='main'>
      <Banner />
      <Bio />
      <Members />
      <div className='home__contact-us'>
        Get in touch with us <a href='mailto:insitu.xr@gmail.com'>here</a>.
      </div>
      <div className='home__ccopyright'>insitu-XR &copy; {new Date().getFullYear()}</div>
    </div>
  );
};

export default Home;
