import React from 'react';
import './Banner.scss';
import { HashLink } from 'react-router-hash-link';

import BannerVideo from '../assets/videos/banner.mp4';
import BannerPoster from '../assets/images/banner.jpg';
import BannerLogo from '../assets/images/logo.png';
const Banner = () => {
  return (
    <div className='videoBanner-container'>
      <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })} to={'/#who-we-are'}>
        <video poster={BannerPoster} className='videoBanner' autoPlay muted loop>
          <source src={BannerVideo} type='video/mp4' />
        </video>
        <div className='videoBanner-title'>
          <img src={BannerLogo} alt='Insitu-XR' />
        </div>
      </HashLink>
    </div>
  );
};

export default Banner;
