import React from 'react';
import './Members.scss';

import Omar from '../assets/images/omar.png';
import Elysha from '../assets/images/elysha.png';

const Members = () => {
  return (
    <div className='members-container'>
      <div className='members-column' id='omar-al-faleh'>
        <div className='members-photo'>
          <img src={Omar} alt='Omar Al Faleh' />
        </div>
        <div className='members-bio'>
          Omar is a digital experiences designer and interactive developer with a practice that spans between New York
          and Montreal. With a background in architecture, arts, and computer science, Omar’s areas of expertise include
          designing and developing for augmented reality, virtual Reality, mobile and web development, user experience
          design, in addition to video projection mapping and interactive lighting. Omar worked with renowned studios
          and artists like Maya Lin, Raphael Lozano-Hemmer, Herman Kolgen, and The Museum of Modern Arts in Montreal,
          and has has worked on pieces that were shown in The Museum of Contemporary Arts in Montreal, Place des Arts in
          Montreal, Today’s Arts festival in the Netherlands, The Institute of Contemporary Arts in London, and the
          Arizona State University theatre. He has designed the media scenography for dance shows and a theatre play
          that were shown in Montreal.
        </div>
      </div>
      <div className='members-column' id='elysha-poirier'>
        <div className='members-photo'>
          <img src={Elysha} alt='Elysha Poirier' />
        </div>
        <div className='members-bio'>
          Elysha Poirier is a multidisciplinary artist and interactive designer working with animation, film and video.
          Combining digital and analog sources she creates intrinsic worlds of their own reflected in mixed media and
          digital 3D environments. Based in Montreal, Elysha is currently experimenting with generative platforms for
          virtual and mixed reality, including experimental web design and data visualization. Elysha's work is deeply
          connected to movement and sound where she translates audio and gesture into real-time motion graphics -
          transporting the viewer to an imaginative world where boundaries and thresholds are rearranged and transposed.
          She's realized a wide range of installations and engaged in live performances for dance, experimental music,
          film, theatre and web.
        </div>
      </div>
    </div>
  );
};

export default Members;
